/* addfiles-icn */

.modal {
  background: rgba(0, 0, 0, 0.15);
  backdrop-filter: blur(5px);
}
.modal-content {
  background: #ffffff;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.15);
  border-radius: 25px;
  text-align: center;
  word-break: break-word;
}
.modal-title {
  font-family: Muli;
  font-style: normal;
  font-size: 16px;
  color: #000000;
  padding: 0;
  margin-bottom: 3%;
}
.modal-subtitle {
  font-family: Muli;
  font-size: 14px;
  line-height: 18px;
  color: #353535;
}
.close {
  text-align: right;
  margin: 2% 4%;
}
.filter-header {
  background: #ffffff;
  font-family: Muli;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 40px;
  text-align: left;
  color: #373737;
  margin-bottom: 2%;
}
.tag-input::placeholder {
  font-family: Muli;
  font-size: 12px;
  line-height: 22px;
  letter-spacing: 0.02em;
  color: #b5b5b5;
}

.required:after {
  content: ' *';
  color: red;
}
