.static-content {
  position: fixed;
  z-index: 10;
  width: 100%;
  padding: 0;
}

#app-overlay {
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 5;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  backdrop-filter: blur(5px);
}

.cover-background {
  height: 100vh;
  background-image: url('../../../public/static_images/landing_background.svg');
  background-size: 100%;
  background-position-y: 12rem;
  background-repeat: no-repeat;
}

.fw {
  font-weight: 600 !important;
}

.link:hover {
  text-decoration: underline;
  cursor: pointer;
  color: #818181;
}

.background-primary {
  background-color: #88305f;
  color: white;
}

.sidebar-right-column {
  width: calc(100vw - 190px);
}

/* search highlight  */
.hlt1 {
  font-weight: bold;
  background-color: #ffedad;
}
