.loader {
  position: absolute;
  top: 40%;
  left: 45%;
  width: 60px !important;
  height: 60px !important;
  z-index: 40;
}

.document-viewer {
  padding: 0 15px 0 30px;
}

.document-viewer > iframe {
  height: calc(100vh - 210px);
}

.MuiAccordionSummary-content.Mui-expanded {
  margin: 15px 0 !important;
}
