.title-input {
  width: 100%;
  height: 30px;
  border: 1px solid #dddddd;
  font-size: 14px;
  line-height: 22px;
  color: #626262;
  font-family: 'Inter';
  padding-left: 0.5rem;
}
.add-datapoint {
  font-family: 'Inter';
  font-size: 14px;
  line-height: 22px;
  color: #88305f;
  text-decoration: underline;
  cursor: pointer;
}
.edit-title-header {
  font-family: 'Inter';
  font-size: 12px;
  line-height: 22px;
  letter-spacing: 0.02em;
  color: #8d8d8d;
}
.MuiPaper-root::before {
  background-color: transparent !important;
}
.tag-type-hoverable {
  text-decoration: underline dotted;
  font-family: 'Inter';
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.02em;
  color: #626262;
}
.raise-request-container {
  font-family: 'Inter';
  font-size: 14px;
  line-height: 15px;
  letter-spacing: 0.02em;
  color: #686868;
}
.tag-input {
  width: 98%;
  padding: 1% 2%;
  padding-right: 3%;
  padding-left: 0.5rem;
  border: 1px solid #dddddd;
  font-size: 12px;
  line-height: 22px;
  font-family: 'Inter';
  outline: none;
  display: flex;
}

/* New CSS for Clauses */

.according-class {
  background-color: #88305f1f !important;
  color: #49454f;
  font-weight: 600;
  font-size: 14px;
  border: none;
  margin-top: 10px !important;
  box-shadow: none !important;
  border-radius: 18px !important;
  padding: 0 16px;
}

.according-class.reminderCardDeleted {
  background-color: rgba(0, 0, 0, 0.15) !important;
}

.according-class.reminderCardDeleted .MuiAvatar-circular {
  background: rgba(0, 0, 0, 0.15);
}

.according-btn {
  width: 99%;
  background-color: #88305f1f !important;
  color: #49454f;
  font-weight: 600;
  font-size: 14px;
  border: none !important;
  margin-top: 10px !important;
  box-shadow: none !important;
  border-radius: 18px !important;
}

.clause-heading {
  padding: 0 !important;
}

.clause-heading .MuiAccordionSummary-content.Mui-expanded {
  margin: 0px !important;
}
.edit-clause-select .title {
  color: #000;
}
.header-wrapper {
  padding: 0 16px;
}
.header-wrapper button {
  height: 3rem;
  font-size: 13px;
  line-height: 18px;
  text-transform: capitalize;
}
.header-wrapper .title-wrapper button {
  padding: 8px 10px;
}
@media (max-width: 767px) {
  .header-wrapper .title-wrapper {
    flex-direction: column;
  }
  .header-wrapper .title-wrapper button {
    height: 1rem;
  }
}
