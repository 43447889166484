@import '../../Design/color.scss';

.keypoint-container {
  border: 1px solid #f9f8f8;
  text-align: center;
  box-sizing: border-box;
  border-radius: 2px;
  padding: 0px 5px;
  cursor: default;
}
.keypoint-data {
  font-size: 10px;
  line-height: 12px;
  text-transform: uppercase;
  color: #858585;
  font-family: Roboto;
}
.keypoint-value {
  font-size: 12px;
  line-height: 15px;
  color: $color-lilac;
  font-family: Arvo;
}
.toc-content {
  box-sizing: border-box;
  border-radius: 2px;
  font-family: 'Inter';
  overflow-wrap: break-word;
  font-style: normal;
  font-size: 14px;
  line-height: 22px;
  margin-bottom: 1.5%;
  margin-left: 0px;
  margin-right: 0px;
  // cursor: pointer;
}
.toc-content-default {
  background: #ffffff;
  border: 1px solid #f2f2f2;
  box-shadow: 1px 1px 8px rgba(0, 0, 0, 0.01);
  font-weight: normal;
  color: #4d4d4d;
}
#jurisdiction-tooltiptext {
  text-align: left;
  border: 1px solid #eeeeee;
  box-sizing: border-box;
  backdrop-filter: blur(4px);
  font-family: 'Inter';
  padding: 5%;
  font-size: 12px;
  line-height: 24px;
  color: #996c84;
  z-index: 2;
  width: max-content;
}

.keypoint-container .tooltip .tooltiptext {
  background: rgba(255, 255, 255, 0.7);
}

.download-style .tooltiptext {
  font-family: 'Inter';
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
  width: 150px;
  align-items: center;
  color: #737373;
  justify-content: center;
  letter-spacing: 0.02em;
  background-color: white;
}
