.MuiAppBar-root {
  border-bottom: 1px solid #e1e1e1 !important;
}

.MuiAccordion-root:before {
  // background-color: transparent !important;
  height: 1.5px !important;
}

.MuiAccordion-root.Mui-expanded:before {
  opacity: 1 !important;
}

.MuiPaper-elevation4 {
  box-shadow: none !important;
}

.MuiTab-root {
  font-size: 1rem !important;
  text-transform: none !important;
}

.MainHeader.OfficeEditorTools .HeaderItems {
  overflow-y: scroll;
}

.MuiTab-root {
  min-width: 110px !important;
}

.text-purple {
  font-weight: 500;
  color: #88305f;
  font-size: 15px;
}

.text-overflow {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

svg.reload-icon {
  width: 18px;
  height: 18px;
  stroke-width: 2em;
  color: '#88305F';
}

svg.edit-icon {
  width: 21px;
  height: 21px;
  stroke-width: 1em;
  margin: 0 10px;
}

svg.svg-delete-icon {
  width: 21px;
  height: 21px;
  margin: 0 10px;
}

img.checked-icon {
  width: 20px;
  height: 20px;
  position: relative;
  top: -3px;
  left: -2px;
}

svg.back-arrow-icon {
  stroke-width: 0.5px;
  width: 20px;
  height: 20px;
}

svg.upload-icon {
  stroke-width: 0.5px;
  width: 18px;
  height: 18px;
  margin-right: 5px;
}

.draft-main .MuiDialog-paperFullScreen {
  background-color: #e5e5e5;
}
.draft-main .MuiBox-root-25 {
  padding: 10px 10px !important;
}
.draft-main .MuiTabs-fixed::-webkit-scrollbar {
  display: none;
}
.MuiCheckbox-colorSecondary.Mui-checked {
  color: rgb(136, 48, 95) !important;
}
.MuiSwitch-colorPrimary.Mui-checked + .MuiSwitch-track {
  background-color: #996c84 !important;
}
.MuiMenu-root .MuiPaper-elevation,
.MuiAutocomplete-popper .MuiPaper-elevation {
  min-width: 18rem !important;
  .MuiMenuItem-root {
    white-space: pre-wrap;
  }
  .MuiAutocomplete-option {
    min-width: 16rem !important;
  }
}
.MuiDataGrid-virtualScrollerContent {
  min-height: 20vh !important;
}

.create-draft-wrapper .MuiDialog-paper {
  max-width: 810px;
}
.MuiTabs-scrollButtons.Mui-disabled {
  display: none;
}
