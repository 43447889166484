// Button pulse animation
@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
}

.pulse-animation {
  animation: pulse 1s infinite;
}

.pulse-animation:hover {
  animation: none;
}

// Border blink animation
@keyframes borderBlink {
  0% {
    border-color: transparent;
  }
  50% {
    border-color: #a0597f;
  }
  100% {
    border-color: transparent;
  }
}

.blink-border-animation {
  border: 2px solid transparent;
  animation: borderBlink 1.5s infinite;
}
