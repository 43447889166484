.footer-wrapper {
  background-color: #fff;
  box-shadow: 0px 0px 0px 1px #00000021;
  padding: 10px 14px;
  margin-top: 1px;

  .footer-chip {
    border-radius: 18px;
    padding: 1px 8px;
    height: 24px;
    background-color: #dcbde7;
    color: #1d192b;
    font-size: 14px;
    line-height: 24px;
  }
}
