.modal-header {
  background-color: #88305f;
  height: auto;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  display: flex;
  color: white;
  align-items: center;
  padding: 10px 16px;
}

.modal-header img {
  height: 20px;
}

::-webkit-input-placeholder {
  padding: 5px;
}

.MuiPaper-root.MuiSnackbarContent-root.MuiPaper-elevation6 {
  background-color: #f0e1e9;
  color: #88305f;
}
