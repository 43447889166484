@import '../../Design/color.scss';

/*Bar Loader*/
.loader-bar-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 150px;
}
.loader-bar {
  animation: bar-loading infinite 1s;
  background: $color-lilac;
  width: 1rem;
  height: 4rem;
  margin: 0 0.2rem;
}
.loader-bar:nth-child(1) {
  animation-delay: 0.8s;
}
.loader-bar:nth-child(2) {
  animation-delay: 1.4s;
}
.loader-bar:nth-child(3) {
  animation-delay: 1.8s;
}
.loader-bar:nth-child(4) {
  animation-delay: 0.6s;
}
.loader-bar:nth-child(5) {
  animation-delay: 1s;
}
.loader-bar:nth-child(6) {
  animation-delay: 0.5s;
}
.loader-bar:nth-child(7) {
  animation-delay: 1.2s;
}

@keyframes bar-loading {
  25% {
    height: 1rem;
    background-color: lightgrey;
  }
  75% {
    height: 5rem;
    background-color: lightgrey;
  }
}

/*Blurry Loader*/
@keyframes blur-load {
  0% {
    opacity: 0.08;
    filter: blur(5px);
    letter-spacing: 3px;
  }
}
