.MuiAppBar-root {
  border-bottom: 1px solid #e1e1e1 !important;
}

.MuiPaper-elevation4 {
  box-shadow: none !important;
}

.MuiTab-root {
  font-size: 1rem !important;
  text-transform: none !important;
}

.hover:hover {
  cursor: pointer;
}
